import './AppointmentEditDateTime.less'

import { Button, DatePicker, Popover, RefSelectProps } from 'antd'
import { FormInstance } from 'antd/lib/form'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { validateRequired } from '../../../../../validation'
import { DatacFormItem, DatacOption, DatacSelect, DatacTimezonesSelect } from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

interface AppointmentEditDateTimeProps {
  isVisible: boolean
  formInstance: FormInstance
}
export const AppointmentEditDateTime: React.VFC<AppointmentEditDateTimeProps> = ({ isVisible, formInstance }) => {
  const intlAppointment = useScopedIntl('calendar.appointment')
  const intl = useScopedIntl('')
  const { appointmentToEdit, userTimezone } = useCalendarStore()

  const [startTime, setStartTime] = useState(dayjs().format('HH:mm'))
  const startTimeRef = useRef<RefSelectProps>()
  const endTimeRef = useRef<RefSelectProps>()
  const [currentStartTime, setCurrentStartTime] = useState('')
  const [currentEndTime, setCurrentEndTime] = useState('')
  const [startTimeOptions, setStartTimeOptions] = useState<DatacOption[]>([])
  const [endTimeOptions, setEndTimeOptions] = useState<DatacOption[]>([])
  const [currentTimezone, setCurrentTimezone] = useState(userTimezone)
  const [isTimezonePopoverOpen, setTimezonePopoverOpen] = useState(false)

  useEffect(() => {
    if (!isVisible) return
    setStartTime(appointmentToEdit?.startTime || dayjs().format('HH:mm'))
    setCurrentStartTime(appointmentToEdit?.startTime || '')
    setCurrentEndTime(appointmentToEdit?.endTime || '')
    setCurrentTimezone(appointmentToEdit?.timezone || userTimezone)
    setStartTimeOptions(getTimeOptions())
    setEndTimeOptions(getTimeOptions(true))
  }, [isVisible])

  useEffect(() => {
    setEndTimeOptions(getTimeOptions(true))
  }, [startTime])

  useEffect(() => {
    const startTime = formInstance.getFieldValue('startTime')
    const startDate = formInstance.getFieldValue('startDate')
    if (!startTime) onPickerChange(startDate)
  }, [startTimeOptions])

  useEffect(() => {
    const endTime = formInstance.getFieldValue('endTime')
    const startTime = formInstance.getFieldValue('startTime')

    if (startTime >= endTime || !endTime) formInstance.resetFields(['endTime'])
  }, [endTimeOptions])

  const getLowestPossibleStartTime = (date: Dayjs) => {
    const now = dayjs()
    const roundedMinutes = Math.ceil(now.minute() / 15) * 15
    return date.isSame(dayjs(), 'day') ? now.minute(roundedMinutes).format('HH:mm') : '00:00'
  }

  const getTimeOptions = (isEnd = false) => {
    const starting = startTime.split(':').map(Number)
    const times = Array.from({ length: 24 }, (_, i) => i).reduce((acc, hour) => {
      if (isEnd && hour < starting[0]) return acc
      ;[0, 15, 30, 45].forEach(minute => {
        if (hour !== starting[0] || minute >= starting[1])
          acc.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`)
      })
      return acc
    }, [])

    if (isEnd) {
      times.shift()
      times.push('23:59')
    }

    const getDurationString = (endTime: string) => {
      if (!isEnd) return ''
      const duration = dayjs.duration(dayjs(endTime, 'HH:mm').diff(dayjs(startTime, 'HH:mm')))
      return `(${intlAppointment('duration', { value: dayjs.utc(duration.as('milliseconds')).format('HH:mm') })})`
    }

    return times.map(time => ({
      value: time,
      label: `${time}`,
      sublabel: getDurationString(time)
    }))
  }

  const onPickerChange = (value: Dayjs) => {
    setStartTimeOptions(getTimeOptions())
    setEndTimeOptions(getTimeOptions(true))
    const possibleStartTime = getLowestPossibleStartTime(value)
    if (startTime <= possibleStartTime) {
      setStartTime(possibleStartTime)
      formInstance.setFieldsValue({ startTime: possibleStartTime })
    }
  }

  const shouldChangeTime = (key: string, time: string, notLessThan: string) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
    return key === 'Enter' && timeRegex.test(time) && time >= notLessThan
  }

  const onStartTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentStartTime, startTimeOptions[0]?.value)) return
    formInstance.setFieldsValue({ startTime: currentStartTime })
    setCurrentStartTime('')
    setStartTime(currentStartTime)
    startTimeRef.current.blur()
  }

  const onEndTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentEndTime, endTimeOptions[0]?.value)) return
    formInstance.setFieldsValue({ endTime: currentEndTime })
    setCurrentEndTime('')
    endTimeRef.current.blur()
  }

  const onTimezoneChange = (timezone: string) => {
    setCurrentTimezone(timezone)
    setTimezonePopoverOpen(false)
  }

  return (
    <>
      <div className="appointment-edit-date-time">
        <DatacFormItem
          label={intlAppointment('date_time.label')}
          showAsterisk
          name="startDate"
          validate={validateRequired(intl('common.required'))}
          icon="calendar"
          initialValue={dayjs()}
          className="appointment-edit-date-time__date"
          additionalHeaderContent={
            <div className="appointment-edit-date-time__timezone">
              <Popover
                content={
                  <div className="appointment-edit-date-time__timezone__popover">
                    <DatacTimezonesSelect showLabel defaultTimezone={currentTimezone} onChange={onTimezoneChange} />
                  </div>
                }
                placement="bottomRight"
                trigger="click"
                open={isTimezonePopoverOpen}
                onOpenChange={setTimezonePopoverOpen}
              >
                <Button type="default">{intl('common.timezone')}</Button>
              </Popover>
            </div>
          }
        >
          <DatePicker size="large" format="dddd, D MMM YYYY" onChange={onPickerChange} />
        </DatacFormItem>
        <DatacFormItem
          label=" "
          name="startTime"
          validate={validateRequired(intl('common.required'))}
          initialValue={startTimeOptions[0]?.value}
        >
          <DatacSelect
            showSearch
            size="large"
            options={startTimeOptions}
            onChange={setStartTime}
            onInputKeyDown={onStartTimeKeyPressed}
            selectRef={startTimeRef}
            onSearch={setCurrentStartTime}
          />
        </DatacFormItem>
        <div className="appointment-edit-date-time__separator">-</div>
        <DatacFormItem
          label=" "
          name="endTime"
          validate={validateRequired(intl('common.required'))}
          initialValue={endTimeOptions[0]?.value}
        >
          <DatacSelect
            showSearch
            size="large"
            options={endTimeOptions}
            onInputKeyDown={onEndTimeKeyPressed}
            selectRef={endTimeRef}
            onSearch={setCurrentEndTime}
            dropDownClassName="appointment-edit-date-time__end-dropdown"
          />
        </DatacFormItem>
      </div>
    </>
  )
}
