import './CalendarContent.less'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Button, Popover } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Calendar, DateLocalizer, Event, dayjsLocalizer } from 'react-big-calendar'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  Appointment,
  AppointmentColor,
  SorterOrder,
  UserConfigKey,
  fetchAppointments as fetchAppointmentsRequest,
  fetchCenters
} from '../../../requests'
import { localeFromPath } from '../../../utils'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacOption, DatacSelect, DatacTitle, FiltersDropdown } from '../../common'
import { CalendarLayout } from '../CalendarLayout'
import { useCalendarStore } from '../CalendarStore'
import { AppointmentEdit } from './AppointmentEdit'
import { AppointmentSearch } from './AppointmentSearch'
import { CalendarDayMore } from './CalendarDayMore'
import { CalendarSettingsPopup } from './CalendarSettingsPopup'
import { EventAgendaComponent, EventMonthComponent, EventWeekComponent } from './EventComponents'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)

export interface DatacEvent extends Event {
  id: number
  eventWeekComponent: React.ReactElement
  eventMonthComponent: React.ReactElement
  eventAgendaComponent: React.ReactElement
  appointment: Appointment
}

export const maxEventsInDay = 6

interface SelectionTime {
  start: Date
  end: Date
}

export const CalendarContent: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlCalendar = useScopedIntl('calendar')
  const {
    currentView,
    setCurrentView,
    currentDate,
    setCurrentDate,
    goToNext,
    goToPrevious,
    requestDates,
    startsOnSunday,
    showWeekends,
    showEarlyHours,
    setAppointmentToEdit,
    userTimezone
  } = useCalendarStore()
  const [events, setEvents] = useState<DatacEvent[]>([])
  const [isShowingSearchEvents, setIsShowingSearchEvents] = useState(false)
  const [isSettingsPopoverVisible, setIsSettingsPopoverVisible] = useState(false)
  const [localizer, setLocalizer] = useState<DateLocalizer>(dayjsLocalizer(dayjs))
  const [selectionTime, setSelectionTime] = useState<SelectionTime>({ start: null, end: null })
  const selectionRef = useRef<HTMLDivElement>(null)
  const { user } = useContext(UserContext)
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [initialCentersOptions, setInitialCentersOptions] = useState<DatacOption[]>([])
  const [isFetchingCenters, setIsFetchingCenters] = useState(true)
  const [filters, setFilters] = useState<Record<string, string[]>>({
    centers: user.getConfigValue<string[]>(UserConfigKey.CalendarCenters, [])
  })

  useEffect(() => {
    fetchCenterOptions('')
  }, [])

  const fetchCenterOptions = (search: string) => {
    fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend }, search }, assignedToUser: true },
      {
        onSuccess: ({ centers }) => {
          const options = centers.map(({ id, abbreviation }) => ({ value: id, label: abbreviation }))
          if (!initialCentersOptions.length) setInitialCentersOptions(options)
          setCentersOptions(options)
          setIsFetchingCenters(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingCenters(false)
        }
      }
    )
  }

  const onFiltersChange = (newFilters: Record<string, string[]>) => {
    setFilters(newFilters)
    user.setConfigValue(UserConfigKey.CalendarCenters, newFilters?.centers || [])
  }

  const onClosePopup = (reload = false) => {
    if (reload) fetchAppointments()
  }

  useEffect(() => {
    if (isFetchingCenters) return
    fetchAppointments()
  }, [requestDates, showWeekends, startsOnSunday, showEarlyHours, userTimezone, filters, isFetchingCenters])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })
    setLocalizer(dayjsLocalizer(dayjs))
  }, [startsOnSunday])

  const shouldHideBecauseOfWeekend = (appointment: Appointment) =>
    (currentView === 'month' || currentView === 'week') &&
    !showWeekends &&
    (appointment.startDate.toDate().getDay() === 6 || appointment.startDate.toDate().getDay() === 0)

  const shouldHideBouseOfMonth = (appointment: Appointment) =>
    currentView === 'month' && !dayjs(appointment.startDate).isSame(currentDate, 'month')

  const fetchAppointments = () => {
    fetchAppointmentsRequest(
      { ...requestDates, centerIds: filters?.centers },
      {
        onSuccess: ({ appointments }) => {
          let eventInDayCount = 0
          let lastDate: Dayjs = null

          const newEvents = appointments
            .filter(appointment => !shouldHideBecauseOfWeekend(appointment) && !shouldHideBouseOfMonth(appointment))
            .map(appointment => {
              const eventComponentProps = { appointment, onClosePopup }

              const start = appointment.startDate.toDate()
              const end = appointment.endDate.toDate()

              if (dayjs(start).isSame(lastDate, 'day')) eventInDayCount += 1
              else eventInDayCount = 1
              lastDate = dayjs(start)

              if (currentView === 'month' && eventInDayCount > maxEventsInDay) return null
              return {
                id: appointment.id,
                start,
                end: ['day', 'week'].includes(currentView) ? end : start,
                title: appointment.title,
                appointment,
                eventWeekComponent: <EventWeekComponent {...eventComponentProps} />,
                eventAgendaComponent: <EventAgendaComponent {...eventComponentProps} />,
                eventMonthComponent:
                  eventInDayCount === maxEventsInDay ? (
                    <CalendarDayMore day={start} appointments={appointments} onClosePopup={onClosePopup} />
                  ) : (
                    <EventMonthComponent {...eventComponentProps} />
                  )
              }
            })
            .filter(el => el)
          setEvents(newEvents)
          setIsShowingSearchEvents(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const title = () => {
    if (currentView === 'day') return format(currentDate, 'dd MMM yyyy')

    return format(currentDate, 'MMM yyyy')
  }

  const onSetSearchEvents = (events: DatacEvent[]) => {
    setEvents(events)
    setIsShowingSearchEvents(true)
  }

  const shouldHideWeekends = currentView !== 'day' && !showWeekends

  const placeSelectionDiv = (top: number, left: number, width: number, height: number) => {
    selectionRef.current.style.top = `${top}px`
    selectionRef.current.style.left = `${left}px`
    selectionRef.current.style.width = `${width}px`
    selectionRef.current.style.height = `${height}px`
  }

  const handleSelectSlot = () => {
    if (!selectionTime.start || !selectionTime.end) return

    setAppointmentToEdit({
      startDate: dayjs(selectionTime.start),
      endDate: dayjs(selectionTime.end),
      startTime: dayjs(selectionTime.start).format('HH:mm'),
      endTime: dayjs(selectionTime.end).format('HH:mm'),
      timezone: userTimezone,
      publicTitle: null,
      title: null,
      capacity: null,
      subjects: [],
      color: AppointmentColor.Blue2,
      study: null,
      center: null
    })

    setSelectionTime({ start: null, end: null })
    placeSelectionDiv(0, 0, 0, 0) // this will remove selection div from the screen
  }

  const onSelecting = ({ start, end }: SelectionTime) => {
    const halfHourInPixels = document.querySelector('.rbc-time-slot').clientHeight
    const fiveMinutesInPixels = halfHourInPixels / 6
    const topOffset = document.querySelector('body').scrollTop
    const leftOffset = document.querySelector('.basic-layout__menu').clientWidth

    // When page with calendar is scrolled down, there is a bug with selection.
    // We need to recalculate selection div position and use our own component instead of default one
    // This bug affects also start and end parameters, not only position of selection div
    // so we are adding both pixels to div and minutes to time
    const minutesToAdd = Math.round((topOffset + fiveMinutesInPixels) / halfHourInPixels) * 30
    const pixelsToAdd = (minutesToAdd * halfHourInPixels) / 30 + topOffset

    // Default selection div is not rendered yet, so we need to wait for it
    setTimeout(() => {
      const selectionElement = document.querySelector('.rbc-slot-selection').getBoundingClientRect()
      placeSelectionDiv(
        selectionElement.top + pixelsToAdd,
        selectionElement.left - leftOffset,
        selectionElement.width,
        selectionElement.height
      )
    }, 0)

    if (start && end && start < end) {
      setSelectionTime({
        start: dayjs(start).add(minutesToAdd, 'minutes').toDate(),
        end: dayjs(end).add(minutesToAdd, 'minutes').toDate()
      })
    }

    return true
  }

  const getMinHour = () => {
    if (showEarlyHours) return undefined

    const timezone = dayjs().tz(userTimezone)
    const browserTimezone = dayjs().tz(dayjs.tz.guess())
    const timezoneOffset = browserTimezone.utcOffset() - timezone.utcOffset()
    const currentDate = new Date()
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDay(),
      7 + timezoneOffset / 60,
      0,
      0
    )
  }

  return (
    <CalendarLayout>
      <div className="calendar-content">
        <div className="calendar-content__header">
          <DatacTitle type="h2">{title()}</DatacTitle>
          <AppointmentSearch setEvents={onSetSearchEvents} />
          <FiltersDropdown
            filters={[
              {
                label: intl('common.centers'),
                name: 'centers',
                handleSearch: fetchCenterOptions,
                options: centersOptions
              }
            ]}
            initialFilterValues={filters}
            onFiltersChange={onFiltersChange}
            isLoading={isFetchingCenters}
            type="icon"
          />
          {(user.canDo(AclFeature.Calendar)(AclAction.Add) || user.canDo(AclFeature.Calendar)(AclAction.Edit)) && (
            <AppointmentEdit onSubmitted={() => fetchAppointments()} centersOptions={initialCentersOptions} />
          )}
        </div>
        <div className="calendar-content__body">
          <div className="calendar-content__body__header">
            <button
              type="button"
              className="calendar-content__body__header__today"
              onClick={() => setCurrentDate(new Date())}
            >
              {intlCalendar('today')}
            </button>
            <button type="button" onClick={() => goToPrevious()}>
              <DatacIcon name="chevronLeft" />
            </button>
            <button type="button" onClick={() => goToNext()}>
              <DatacIcon name="chevronRight" />
            </button>

            <div className="calendar-content__body__header__right">
              <DatacSelect
                options={['day', 'week', 'month', 'agenda'].map(v => ({ value: v, label: intlCalendar(v) }))}
                onChange={v => setCurrentView(v)}
                value={currentView}
              />
              <Popover
                trigger="click"
                placement="bottomRight"
                open={isSettingsPopoverVisible}
                onOpenChange={setIsSettingsPopoverVisible}
                content={<CalendarSettingsPopup />}
              >
                <Button type="default">
                  <DatacIcon raw name="settings" type="grey" />
                </Button>
              </Popover>
            </div>
          </div>
          {isShowingSearchEvents ? (
            <div className="calendar-content__body__search-results">
              {events.map(event => (
                <div className="calendar-content__body__search-results__event" key={event.id}>
                  {event.eventAgendaComponent}
                </div>
              ))}
            </div>
          ) : (
            <Calendar
              className={classNames(
                'calendar-content__body__calendar',
                `calendar-content__body__calendar--${currentView}`,
                shouldHideWeekends && startsOnSunday && 'calendar-content__body__calendar--hide-first-and-last',
                shouldHideWeekends && !startsOnSunday && 'calendar-content__body__calendar--hide-last-two'
              )}
              style={{ height: '100%', width: '100%' }}
              min={getMinHour()}
              date={currentDate}
              view={currentView}
              events={events}
              localizer={localizer}
              culture={localeFromPath()}
              formats={{
                timeGutterFormat: 'HH:mm',
                agendaTimeFormat: 'HH:mm'
              }}
              selectable={currentView !== 'month'}
              scrollToTime={currentDate}
              onNavigate={date => setCurrentDate(date)}
              onSelectSlot={handleSelectSlot}
              onView={view => setCurrentView(view)}
              onSelecting={onSelecting}
              showMultiDayTimes={currentView === 'week'}
              components={{
                week: {
                  header: ({ date, localizer }) => (
                    <>
                      <div>{localizer.format(date, 'DD')}</div>
                      {localizer.format(date, 'dddd').toUpperCase()}
                    </>
                  ),
                  event: ({ event }) => events.find(el => el.id === event.id).eventWeekComponent
                },
                month: {
                  event: ({ event }) => events.find(el => el.id === event.id).eventMonthComponent
                },
                day: {
                  event: ({ event }) => events.find(el => el.id === event.id).eventWeekComponent
                },
                agenda: {
                  event: ({ event }) => events.find(el => el.id === event.id).eventAgendaComponent,
                  date: () => <></>
                }
              }}
            />
          )}
        </div>
      </div>
      <div ref={selectionRef} className="calendar-content__body__calendar__selection" />
    </CalendarLayout>
  )
}
